// Constants
import { Component } from '@/Constants/Component'

// Class Export
export class BaseElement {
	private key: string
	private label: string
	private params: BaseElementParams

	constructor() {
		this.params = {}
	}

	public get Key() {
		return this.key
	}

	public set Key(value) {
		this.key = value
	}

	public get Label() {
		return this.label
	}

	public set Label(value) {
		this.label = value
	}

	public get Params() {
		return this.params
	}

	public isParamsOptional() {
		this.params.optional = true
		return this
	}

	public setParamsPlaceHolder(placeholder: string) {
		this.params.placeholder = placeholder
		return this
	}

	public setParamsReadOnly(...readonly: Component.Actions[]) {
		this.params.readonly = readonly
		return this
	}

	public setParamsState(f: (self: any) => boolean) {
		this.params.state = f
		return this
	}

	public setParamsValidator(f: (value: any) => boolean) {
		this.params.validator = f
		return this
	}

	public setParamsVisible(...visible: Array<Component.Actions>) {
		this.params.visible = visible
		return this
	}
}

// Interfaces
interface BaseElementParams {
	button?: any
	enabled?: boolean
	formatter?: any
	optional?: boolean
	placeholder?: string
	range?: { max?: number, min?: number }
	readonly?: Array<Component.Actions>
	requires?: any
	state?: (self: any) => boolean
	type?: string
	validator?: (value: any) => boolean
	visible?: Array<Component.Actions>
}