// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Components (.vue)
import CompanyLogo  from '@/Components/Global/CompanyLogo/template.vue'
import DataTable    from '@/Components/Global/DataTable/template.vue'
import DataForm     from '@/Components/Global/DataForm/template.vue'
import SubmitButton from '@/Components/Global/SubmitButton/template.vue'

// Components (Refs)
import { DataTableRef } from '@/Components/Global/DataTable/component'
import { DataFormRef }  from '@/Components/Global/DataForm/component'

// Constants
import { Vuex } from '@/Constants/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const PopupUserForm = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'PopupUserForm',

	components: {
		CompanyLogo,
		DataForm,
		DataTable,
		SubmitButton
	},

	props: {
		cancelButtonText: { type: String, default: 'Cerrar' },
		dataFormAction: Number,
		preventDefaults: { type: Boolean, default: false },
		show: Boolean,
		showStoragesList: Boolean,
		submitButtonTextOnInsert: String,
		submitButtonTextOnRead: String,
		submitButtonTextOnUpdate: String
	},

	data: function() {
		return {
			dataForm: undefined as any,
			inputButtonClicked: undefined as string,
			states: {
				isFetching: false,
				secondaryButtonAcceptText: 'Aceptar',
				secondaryButtonCancelText: 'Cancelar',
				showSecondaryButtonAccept: false,
				showSecondaryButtonCancel: false,
				showUpdate: true
			}
		}
	},

	mounted: function() {
		this._dataForm.setStates<DataFormRef['states']>({ action: this.dataFormAction })
		this._initPermissions()
	},

	computed: {
		_dataForm: function(): DataFormRef {
			return this.$refs.dataForm as DataFormRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		}
	},

	methods: {
		_initPermissions: function() {
			if (this.showStoragesList) {
				this._dataTable.setPermission('ACTION_DELETE', true)
				this._dataTable.setPermission('NEW_BUTTON', true)
			}
		},

		getDataFormCurrentTab: function() {
			return this._dataForm.getCurrentTab()
		},

		onClose: function() {
			DevelopmentTools.printWarn('[PopupUserForm]:onClose event triggered')
			this.$emit('onClose')
		},

		onDTButtonClick: function(key: string, row: any) {
			DevelopmentTools.printWarn('[PopupUserForm]:onDTButtonClick event triggered')
			this.$emit('onDTButtonClick', key, row)
		},

		onDTNewButtonClick: function(key: string) {
			DevelopmentTools.printWarn('[PopupUserForm]:onDTNewButtonClick event triggered')
			this.inputButtonClicked = key
			this.$emit('onDTNewButtonClick')
		},

		onInputButtonClick: function(key: string, id: string) {
			DevelopmentTools.printWarn('[PopupUserForm]:onInputButtonClick event triggered')
			if (!this.preventDefaults) this._dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			this.inputButtonClicked = key
			this.$emit('onInputButtonClick', key, id)
		},

		onOptionChange: function(key: string, id: string) {
			DevelopmentTools.printWarn('[PopupUserForm]:onOptionChange event triggered')
			this.$emit('onOptionChange', key, id)
		},

		onPTSelect: function(currentRow: object) {
			DevelopmentTools.printWarn('[PopupUserForm]:onPTSelect event triggered')
			this.$emit('onPTSelect', this.inputButtonClicked, currentRow)
		},

		onPUFCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFCurrentSearchPaginationChanged event triggered')
			this.$emit('onPUFCurrentSearchPaginationChanged', page, searchParams)
		},

		onPUFPaginationChanged: function(page: number) {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFPaginationChanged event triggered')
			this.$emit('onPUFPaginationChanged', page)
		},

		onPUFRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFRefreshButtonClick event triggered')
			this.$emit('onPUFRefreshButtonClick', this.inputButtonClicked)
		},

		onPUFSearchButtonClicked: function(searchParams: any) {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFSearchButtonClicked event triggered')
			this.$emit('onPUFSearchButtonClicked', searchParams)
		},

		onPUFSecondaryButtonClick: function(key: string) {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFSecondaryButtonClick event triggered')
			this.$emit('onPUFSecondaryButtonClick', key)
		},

		onPUFSubmit: function() {
			DevelopmentTools.printWarn('[PopupUserForm]:onPUFSubmit event triggered')
			this.$emit('onPUFSubmit')
		},

		onRowDoubleClick: function(clickedRow: object) {
			DevelopmentTools.printWarn('[PopupUserForm]:onRowDoubleClick():Event triggered')
			this.$emit('onRowDoubleClick', this.inputButtonClicked, clickedRow)
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
		'dataFormAction': function() { this._dataForm.setStates<DataFormRef['states']>({ action: this.dataFormAction }) }
	}
})

// Exports
export default PopupUserForm
export type PopupUserFormRef = InstanceType<typeof PopupUserForm>